<template>
  <div class="mb-2">
    <Subtitle subtitle="Productos Asociados" />
    <Supermarkets />
    <Ingredients />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    Subtitle: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Subtitle" */ '@/modules/cms/components/Subtitle.vue'
      )
    ),
    Supermarkets: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Supermarkets" */ '@/modules/cms/parts/ingredients/parnetProduct/Supermarkets.vue'
      )
    ),
    Ingredients: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "Ingredients" */ '@/modules/cms/parts/ingredients/parnetProduct/Ingredients.vue'
      )
    ),
  },
};
</script>
